import scrollTo from "gatsby-plugin-smoothscroll"
import React from "react"

import { graphql, useStaticQuery } from "gatsby"

export const Footer = () => {
  const data = useStaticQuery(graphql`
    {
      bgFooter: file(relativePath: { eq: "bg-footer.jpg" }) {
        publicURL
      }
    }
  `)

  return (
    <section className="md:flex h-full">
      <div
        className="p-8 bg-cover bg-center w-screen h-auto md:w-1/2 lg:w-3/5 xl:p-16"
        style={{ backgroundImage: `url(${data.bgFooter.publicURL})` }}
      >
        <div className="w-fit-content flex flex-col mb-6 md:mb-8 lg:mb-12">
          <span className="text-white text-3xl font-semibold xl:text-4xl">
            GMG
          </span>
          <span className="text-white xl:text-lg">
            グレースメディカルグループ
          </span>
          <div className="block mt-2">
            <div className="w-full h-1 bg-primary-blue" />
            <div className="w-full h-1 bg-primary-red" />
            <div className="w-full h-1 bg-primary-blue" />
          </div>
        </div>
        <a
          href="https://grace-dc.com/"
          target="_blank"
          rel="noreferrer"
          className="flex items-center w-fit-content px-6 py-3 bg-white rounded-full gap-x-6 mb-4 lg:px-8 lg:mb-6 xl:px-10 lg:py-4 group transition duration-500 ease-in-out hover:bg-blue-900"
        >
          <span className="font-medium text-sm lg:text-base xl:text-lg group-hover:text-white transition duration-500 ease-in-out">
            医療法人社団 慶実会 公式HP
          </span>
          <span>
            <svg
              className="w-4 h-4 fill-current text-blue-900 opacity-70 group-hover:text-white group-hover:opacity-100 transition duration-500 ease-in-out"
              viewBox="0 0 18 18"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M17.1818 0H10.6364C10.1845 0 9.81817 0.366328 9.81817 0.818191C9.81817 1.27005 10.1845 1.63638 10.6364 1.63638H15.2066L6.78513 10.0579C6.46567 10.3773 6.46567 10.8954 6.78513 11.2149C6.94485 11.3746 7.15424 11.4545 7.36363 11.4545C7.57302 11.4545 7.78244 11.3746 7.94212 11.2149L16.3637 2.79345V7.36365C16.3637 7.81552 16.73 8.18184 17.1819 8.18184C17.6337 8.18184 18 7.81552 18 7.36365V0.818191C18 0.366328 17.6337 0 17.1818 0Z" />
              <path d="M13.9091 8.18203C13.4572 8.18203 13.0909 8.54836 13.0909 9.00022V16.3639H1.63635V4.9093H9C9.45186 4.9093 9.81819 4.54297 9.81819 4.09111C9.81819 3.63924 9.45186 3.27295 9 3.27295H0.818191C0.366328 3.27295 0 3.63928 0 4.09114V17.1821C0 17.6339 0.366328 18.0002 0.818191 18.0002H13.9091C14.361 18.0002 14.7273 17.6339 14.7273 17.182V9.00022C14.7273 8.54836 14.3609 8.18203 13.9091 8.18203Z" />
            </svg>
          </span>
        </a>
        <a
          href="https://gmg-keiikukai.com/"
          target="_blank"
          rel="noreferrer"
          className="flex items-center w-fit-content px-6 py-3 bg-white rounded-full gap-x-6 mb-4 lg:px-8 lg:mb-6 xl:px-10 lg:py-4 group transition duration-500 ease-in-out hover:bg-blue-900"
        >
          <span className="font-medium text-sm lg:text-base xl:text-lg group-hover:text-white transition duration-500 ease-in-out">
            医療法人社団 慶育会 公式HP
          </span>
          <span>
            <svg
              className="w-4 h-4 fill-current text-blue-900 opacity-70 group-hover:text-white group-hover:opacity-100 transition duration-500 ease-in-out"
              viewBox="0 0 18 18"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M17.1818 0H10.6364C10.1845 0 9.81817 0.366328 9.81817 0.818191C9.81817 1.27005 10.1845 1.63638 10.6364 1.63638H15.2066L6.78513 10.0579C6.46567 10.3773 6.46567 10.8954 6.78513 11.2149C6.94485 11.3746 7.15424 11.4545 7.36363 11.4545C7.57302 11.4545 7.78244 11.3746 7.94212 11.2149L16.3637 2.79345V7.36365C16.3637 7.81552 16.73 8.18184 17.1819 8.18184C17.6337 8.18184 18 7.81552 18 7.36365V0.818191C18 0.366328 17.6337 0 17.1818 0Z" />
              <path d="M13.9091 8.18203C13.4572 8.18203 13.0909 8.54836 13.0909 9.00022V16.3639H1.63635V4.9093H9C9.45186 4.9093 9.81819 4.54297 9.81819 4.09111C9.81819 3.63924 9.45186 3.27295 9 3.27295H0.818191C0.366328 3.27295 0 3.63928 0 4.09114V17.1821C0 17.6339 0.366328 18.0002 0.818191 18.0002H13.9091C14.361 18.0002 14.7273 17.6339 14.7273 17.182V9.00022C14.7273 8.54836 14.3609 8.18203 13.9091 8.18203Z" />
            </svg>
          </span>
        </a>
        <a
          href="https://a-hmc.com/"
          target="_blank"
          rel="noreferrer"
          className="flex items-center w-fit-content px-6 py-3 bg-white rounded-full gap-x-6 lg:px-8 xl:px-10 lg:py-4 group transition duration-500 ease-in-out hover:bg-blue-900"
        >
          <span className="font-medium text-sm lg:text-base xl:text-lg group-hover:text-white transition duration-500 ease-in-out">
            一般社団法人 在宅医療協会 公式HP
          </span>
          <span>
            <svg
              className="w-4 h-4 fill-current text-blue-900 opacity-70 group-hover:text-white group-hover:opacity-100 transition duration-500 ease-in-out"
              viewBox="0 0 18 18"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M17.1818 0H10.6364C10.1845 0 9.81817 0.366328 9.81817 0.818191C9.81817 1.27005 10.1845 1.63638 10.6364 1.63638H15.2066L6.78513 10.0579C6.46567 10.3773 6.46567 10.8954 6.78513 11.2149C6.94485 11.3746 7.15424 11.4545 7.36363 11.4545C7.57302 11.4545 7.78244 11.3746 7.94212 11.2149L16.3637 2.79345V7.36365C16.3637 7.81552 16.73 8.18184 17.1819 8.18184C17.6337 8.18184 18 7.81552 18 7.36365V0.818191C18 0.366328 17.6337 0 17.1818 0Z" />
              <path d="M13.9091 8.18203C13.4572 8.18203 13.0909 8.54836 13.0909 9.00022V16.3639H1.63635V4.9093H9C9.45186 4.9093 9.81819 4.54297 9.81819 4.09111C9.81819 3.63924 9.45186 3.27295 9 3.27295H0.818191C0.366328 3.27295 0 3.63928 0 4.09114V17.1821C0 17.6339 0.366328 18.0002 0.818191 18.0002H13.9091C14.361 18.0002 14.7273 17.6339 14.7273 17.182V9.00022C14.7273 8.54836 14.3609 8.18203 13.9091 8.18203Z" />
            </svg>
          </span>
        </a>
      </div>
      <div className="p-8 pb-4 bg-blue-700 bg-opacity-10 md:w-1/2 lg:w-2/5 xl:p-16">
        <div className="flex flex-col gap-y-4">
          <button
            className="flex flex-wrap items-center gap-x-4 w-fit-content"
            onClick={() => scrollTo("html")}
          >
            <span className="text-sm text-medium lg:text-base hover:underline">
              ページトップ
            </span>
            <svg
              className="w-3 h-3"
              viewBox="0 0 10 10"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.90965 4.47505L9.6066 0.535453L5.66701 0.232407L5.73842 1.16084L8.09299 1.34196L0.767767 8.66718L1.47487 9.37429L8.8001 2.04907L8.98122 4.40363L9.90965 4.47505Z"
                fill="#2E3A59"
              />
            </svg>
          </button>
          <button
            className="flex items-center gap-x-4 w-fit-content"
            onClick={() => scrollTo("#concept-movie")}
          >
            <span className="text-sm text-medium lg:text-base hover:underline">
              紹介動画
            </span>
            <svg
              className="w-3 h-3"
              viewBox="0 0 10 10"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.90965 4.47505L9.6066 0.535453L5.66701 0.232407L5.73842 1.16084L8.09299 1.34196L0.767767 8.66718L1.47487 9.37429L8.8001 2.04907L8.98122 4.40363L9.90965 4.47505Z"
                fill="#2E3A59"
              />
            </svg>
          </button>
          <button
            className="flex items-center gap-x-4 w-fit-content"
            onClick={() => scrollTo("#banner01")}
          >
            <span className="text-sm text-medium lg:text-base hover:underline">
              医療を紡ぐ01
            </span>
            <svg
              className="w-3 h-3"
              viewBox="0 0 10 10"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.90965 4.47505L9.6066 0.535453L5.66701 0.232407L5.73842 1.16084L8.09299 1.34196L0.767767 8.66718L1.47487 9.37429L8.8001 2.04907L8.98122 4.40363L9.90965 4.47505Z"
                fill="#2E3A59"
              />
            </svg>
          </button>
          <button
            className="flex items-center gap-x-4 w-fit-content"
            onClick={() => scrollTo("#banner02")}
          >
            <span className="text-sm text-medium lg:text-base hover:underline">
              医療を紡ぐ02
            </span>
            <svg
              className="w-3 h-3"
              viewBox="0 0 10 10"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.90965 4.47505L9.6066 0.535453L5.66701 0.232407L5.73842 1.16084L8.09299 1.34196L0.767767 8.66718L1.47487 9.37429L8.8001 2.04907L8.98122 4.40363L9.90965 4.47505Z"
                fill="#2E3A59"
              />
            </svg>
          </button>
        </div>
        <div className="mt-12 text-center">
          <span className="text-xs font-medium">
            ©︎ 2021 Grace Medical Group.
          </span>
        </div>
      </div>
    </section>
  )
}

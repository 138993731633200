import React, { useEffect } from "react"
import PuffLoader from "react-spinners/PuffLoader"
import { gsap } from "gsap"

export const Wrapper = () => {
  useEffect(() => {
    const tl = gsap.timeline()

    tl.to(".wrapper", {
      opacity: 0,
      duration: 1.2,
      delay: 0.8,
      ease: "easeInOut",
    })
      .to("body", {
        className: "+=overflow-auto",
      })
      .to(".wrapper", {
        className: "+=hidden",
      })
  })
  return (
    <div className="w-screen h-screen fixed inset-0 wrapper z-50">
      <div className="w-full h-full bg-wrapper">
        <div className="w-full h-full flex flex-col items-center justify-center gap-y-4">
          <span className="w-full text-center text-white md:text-xl xl:text-2xl leading-loose">
            "Be With Customers"
            <br />
            お客様に寄り添った医療サービスを
          </span>
          <PuffLoader color="#ffffff" size={40} />
        </div>
      </div>
    </div>
  )
}

import * as React from "react"

import {
  Banner01,
  Banner02,
  ConceptMovie,
  Container,
  Footer,
  Greeting,
  Career,
  Top,
  Wrapper,
} from "../components"
import Seo from "../components/Seo"

const IndexPage = () => (
  <Container>
    <Seo title="Be With Customers" />
    <Wrapper />
    <Top />
    <Greeting />
    <ConceptMovie />
    <Banner01 />
    <Banner02 />
    <Career />
    <Footer />
  </Container>
)

export default IndexPage

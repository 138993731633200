import React, { useEffect, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"

import { Modal } from "./Modal"

import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

export const ConceptMovie = () => {
  useEffect(() => {
    const body = document.querySelector("body")
    body.style.overflow = `${showModal ? `hidden` : `auto`}`

    if (typeof window !== "undefined") {
      gsap.registerPlugin(ScrollTrigger)
    }

    gsap.to(".concept-movie", {
      opacity: 1,
      y: "0",
      duration: "1.6",
      scrollTrigger: {
        trigger: ".concept-movie",
        start: "top bottom",
      },
    })
  })

  const data = useStaticQuery(graphql`
    {
      thumbnail: file(relativePath: { eq: "thumbnail.jpeg" }) {
        publicURL
      }
    }
  `)

  const [showModal, setShowModal] = useState(false)

  const openModal = () => {
    setShowModal(prev => !prev)
  }

  return (
    <section
      id="concept-movie"
      className="bg-blue-700 bg-opacity-10 my-12 py-12 md:bg-transparent xl:py-0 xl:my-56"
    >
      <h3 className="px-4 flex flex-col gap-y-2 mb-8 md:mb-0 lg:px-8 xl:pl-32">
        <span className="text-blue-900 text-sm md:hidden">Concept Movie</span>
        <span className="text-lg lg:text-xl xl:text-2xl">紹介動画</span>
      </h3>
      <div className="md:flex relative">
        <div className="z-10 px-4 mb-8 md:w-1/2 md:mb-0 md:pr-0 md:absolute md:left-0 md:top-1/2 md:transform md:-translate-y-1/2 lg:px-8 lg:pr-16 xl:pl-32 xl:pr-32">
          <button
            onClick={openModal}
            className="concept-movie opacity-0 transform translate-y-4 group w-full h-56 relative lg:h-64 xl:h-96"
          >
            <div
              className="bg-cover bg-center w-full h-full"
              style={{ backgroundImage: `url(${data.thumbnail.publicURL})` }}
            />
            <svg
              className="absolute right-4 bottom-4 w-14 h-14 opacity-50 group-hover:opacity-100 transition duration-500 flex items-center justify-center xl:w-20 xl:h-20 xl:right-6 xl:bottom-10"
              viewBox="0 0 71.412065 50"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="m35.705078 0s-22.351218.0001149-27.939453 1.4707031c-3 .8235294-5.4713925 3.2946921-6.2949219 6.3535157-1.4705882 5.5882352-1.4707031 17.1757812-1.4707031 17.1757812s.0001149 11.64637 1.4707031 17.175781c.8235294 3.058824 3.2360984 5.471393 6.2949219 6.294922 5.647059 1.529412 27.939453 1.529297 27.939453 1.529297s22.353171-.000115 27.941406-1.470703c3.058824-.82353 5.471393-3.236098 6.294922-6.294922 1.470588-5.588235 1.470703-17.175781 1.470703-17.175781s.058709-11.64614-1.470703-17.2343752c-.823529-3.0588236-3.236098-5.4713925-6.294922-6.2949219-5.588235-1.52941178-27.941406-1.5292969-27.941406-1.5292969zm-7.117187 14.294922 18.58789 10.705078-18.58789 10.705078z"
                fill="#fff"
                transform="scale(.999999993)"
              />
            </svg>
          </button>
        </div>
        <div className="px-4 md:w-7/12 md:bg-blue-700 md:bg-opacity-10 md:py-12 md:pr-0 md:ml-auto lg:py-24 lg:w-2/3">
          <div className="w-fit-content md:ml-16 lg:ml-auto lg:pr-8 xl:mx-auto xl:p-0">
            <h3 className="hidden md:block text-blue-900 text-lg lg:text-xl xl:text-2xl">
              Concept Movie
            </h3>
            <h4 className="text-sm leading-sm my-6 md:text-xl md:font-medium lg:text-2xl xl:text-3xl xl:my-8">
              お客様に寄り添った医療サービスを
            </h4>
            <p className="text-sm leading-sm lg:text-base lg:leading-base xl:text-lg xl:leading-lg">
              私たちの大切にしたい想いが詰まった企業ムービーです。
              <br />
              <br />
              利用者様、求職者の方々にも、医療関係者の方にも、
              <br />
              ぜひご覧いただきたい映像です。
              <br />
            </p>
          </div>
        </div>
      </div>
      <Modal showModal={showModal} setShowModal={setShowModal} />
    </section>
  )
}

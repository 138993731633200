import React, { useEffect } from "react"
import { StaticImage } from "gatsby-plugin-image"

import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

export const Banner01 = () => {
  useEffect(() => {
    if (typeof window !== "undefined") {
      gsap.registerPlugin(ScrollTrigger)
    }

    gsap.from(".banner01-title", {
      opacity: 0,
      y: "80",
      duration: "1",
      scrollTrigger: {
        trigger: ".banner01-title",
        start: "50% bottom",
      },
    })
    gsap.from(".banner01-image-1", {
      opacity: 0,
      y: "16",
      duration: "1.6",
      scrollTrigger: {
        trigger: ".banner01-image-1",
        start: "top bottom",
      },
    })

    gsap.from(".banner01-text-1", {
      opacity: 0,
      y: "80",
      duration: "1",
      scrollTrigger: {
        trigger: ".banner01-text-1",
        start: "50% bottom",
      },
      stagger: {
        each: "0.2",
      },
    })
    gsap.from(".banner01-image-2", {
      opacity: 0,
      y: "16",
      duration: "1.6",
      scrollTrigger: {
        trigger: ".banner01-image-2",
        start: "top bottom",
      },
    })

    gsap.from(".banner01-text-2", {
      opacity: 0,
      y: "80",
      duration: "1",
      scrollTrigger: {
        trigger: ".banner01-text-2",
        start: "50% bottom",
      },
    })
    gsap.from(".banner01-image-3", {
      opacity: 0,
      y: "16",
      duration: "1.6",
      scrollTrigger: {
        trigger: ".banner01-image-3",
        start: "top bottom",
      },
    })
  })

  return (
    <section
      id="banner01"
      className="mt-12 pt-12 md:mb-8 lg:mb-12 xl:pt-0 xl:mt-56 xl:mb-32"
    >
      <div className="md:flex items-center md:mb-16">
        <h3 className="banner01-title px-6 flex flex-col gap-y-4 md:w-1/2 lg:pl-8 xl:w-2/5 ml-auto">
          <span className="text-red-300 opacity-80 text-sm lg:text-lg">
            医療を紡ぐ01
          </span>
          <span className="text-lg leading-8 lg:text-2xl lg:leading-sm xl:text-3xl xl:leading-loose">
            私たちがこだわる品質とは、
            <br />
            GMGスタッフのおもいの強さ。
          </span>
        </h3>
        <div className="banner01-image-1 w-3/4 ml-auto my-8 md:w-1/2 md:my-0 xl:w-1/3">
          <StaticImage
            className="object-cover object-top w-full h-full"
            src="../assets/img/banner-01-1.jpeg"
            alt="医療を紡ぐ01-1 画像"
            placeholder="blurred"
            quality="100%"
          />
        </div>
      </div>
      <div className="flex flex-col-reverse md:flex-row md:items-center lg:gap-x-16 xl:gap-x-24">
        <div className="banner01-image-2 w-3/5 my-8 md:w-2/5 lg:w-1/2">
          <StaticImage
            className="object-cover w-full h-full"
            src="../assets/img/banner-01-3.jpeg"
            alt="医療を紡ぐ01-2 画像"
            placeholder="blurred"
            quality="100%"
          />
        </div>
        <div className="px-6 md:pl-0 md:ml-auto lg:pr-24 xl:p-0 xl:ml-0">
          <p className="banner01-text-1 text-sm leading-sm lg:text-base lg:leading-base ">
            私たちはひとりでも多くの方に、
            <br />
            心から満たされた人生をお送りして欲しい
            <br />
            と願っております。
            <br />
            <br />
          </p>
          <p className="banner01-text-1 text-sm leading-sm lg:text-base lg:leading-base ">
            そのために、すべての方が気軽に
            <br />
            質の高い医療をお受けいただける様、
            <br />
            医科、歯科の垣根を超えた
            <br />
            多様なサービスメニューをご用意しております。
            <br />
            <br />
          </p>
          <p className="banner01-text-1 text-sm leading-sm lg:text-base lg:leading-base ">
            そして、皆様から必要とされる
            <br />
            社会インフラとなる医療機関として
            <br />
            今後も進化し続けます。
            <br />
          </p>
        </div>
      </div>
      {/* <div className="flex flex-col-reverse -mt-32 md:flex-row md:items-center md:-mt-16 lg:gap-x-16 lg:-mt-24 lg:items-end xl:gap-x-24">
        <p className="banner01-text-2 text-sm leading-sm px-6 lg:ml-auto lg:px-0 lg:text-base lg:leading-base lg:pb-8 ">
          患者さま、ご家族が安心して在宅医療を
          <br />
          受診できるよう心がけております。
          <br />
          私たちは皆様の健康と安心の生活を
          <br />
          お約束いたします。
          <br />
        </p>
        <div className="banner01-image-3 w-5/12 mb-8 ml-auto mr-6 lg:ml-0 lg:mb-0 lg:mr-24 lg:w-1/3 xl:w-1/4 xl:mr-auto xl:ml-24">
          <StaticImage
            className="object-cover w-full h-full"
            src="../assets/img/banner-01-3.jpg"
            alt="医療を紡ぐ01-3 画像"
            placeholder="blurred"
            quality="100%"
          />
        </div>
      </div> */}
    </section>
  )
}

import React, { useRef } from "react"

export const Modal = ({ showModal, setShowModal }) => {
  const modalRef = useRef()
  const closeModal = e => {
    if (modalRef.current === e.target) {
      setShowModal(false)
    }
  }

  return (
    <>
      {showModal ? (
        <section
          ref={modalRef}
          onClick={closeModal}
          className="z-50 fixed inset-0 w-screen h-screen overflow-hidden bg-blue-100 flex items-center justify-center"
        >
          <iframe
            className="max-w-6xl mx-auto w-full h-48 md:px-8 md:h-1/2 lg:px-16 lg:h-2/3"
            src="https://www.youtube.com/embed/R8LGowrJkEA"
            title="【GMG - グレースメディカルグループ】コンセプトムービー"
            type="text/html"
            id="ytplayer"
            frameborder="0"
            allowFullScreen
          ></iframe>
          <button
            onClick={() => setShowModal(prev => !prev)}
            className="absolute top-4 right-4 lg:top-8 lg:right-8"
          >
            <svg
              className="w-6 h-6 md:w-8 md:h-8"
              viewBox="0 0 40 40"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0)">
                <path
                  d="M20.9159 19.9957L39.8109 1.10069C40.0627 0.848804 40.0627 0.440496 39.8109 0.18861C39.559 -0.0631955 39.1507 -0.0631955 38.8988 0.18861L20.0038 19.0836L1.10881 0.18861C0.85257 -0.0588416 0.444263 -0.0517462 0.196731 0.204494C-0.0446736 0.454445 -0.0446736 0.850739 0.196731 1.10069L19.0917 19.9957L0.196731 38.8907C-0.0595094 39.1383 -0.0666048 39.5466 0.180927 39.8028C0.42846 40.059 0.836767 40.0661 1.09301 39.8186C1.09841 39.8134 1.10365 39.8081 1.10881 39.8028L20.0038 20.9078L38.8988 39.8028C39.1551 40.0503 39.5634 40.0432 39.8109 39.787C40.0524 39.537 40.0524 39.1407 39.8109 38.8907L20.9159 19.9957Z"
                  fill="#333333"
                />
              </g>
              <defs>
                <clipPath id="clip0">
                  <rect width="40" height="40" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </button>
        </section>
      ) : null}
    </>
  )
}

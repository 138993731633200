import React, { useEffect } from "react"
import { StaticImage } from "gatsby-plugin-image"

import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

export const Career = () => {
  useEffect(() => {
    if (typeof window !== "undefined") {
      gsap.registerPlugin(ScrollTrigger)
    }

    gsap.from(".career-image", {
      opacity: 0,
      y: "80",
      duration: "1",
      scrollTrigger: {
        trigger: ".career-image",
        start: "50% bottom",
      },
      stagger: {
        each: "0.2",
      },
    })
  })
  return (
    <section
      id="career"
      className="md:flex gap-x-4 items-center mb-8 lg:gap-x-8 xl:gap-x-16 lg:mb-16 lg:items-end"
    >
      <div className="hidden lg:block xl:px-32 px-6 flex items-center justify-center mx-auto">
        <StaticImage
          className="career-image w-full h-full"
          src="../assets/img/area-banner.webp"
          alt="募集エリア"
          placeholder="blurred"
          quality="100%"
        />
      </div>
      <div className="lg:hidden px-6 flex items-center justify-center mx-auto">
        <StaticImage
          className="career-image w-full h-full"
          src="../assets/img/area-banner-sp.webp"
          alt="募集エリア"
          placeholder="blurred"
          quality="100%"
        />
      </div>
    </section>
  )
}

import React, { useEffect } from "react"
import { StaticImage } from "gatsby-plugin-image"

import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

export const Greeting = () => {
  useEffect(() => {
    if (typeof window !== "undefined") {
      gsap.registerPlugin(ScrollTrigger)
    }

    gsap.from(".greeting-text", {
      opacity: 0,
      y: "80",
      duration: "1",
      scrollTrigger: {
        trigger: ".greeting-text",
        start: "50% bottom",
      },
      stagger: {
        each: "0.2",
      },
    })
    gsap.from(".greeting-image", {
      opacity: 0,
      y: "16",
      duration: "1.6",
      scrollTrigger: {
        trigger: ".greeting-image",
        start: "top bottom",
      },
    })
  })
  return (
    <section
      id="greeting"
      className="md:flex gap-x-4 items-center my-24 lg:gap-x-8 xl:gap-x-16 xl:my-56 lg:items-start"
    >
      <div className="px-4 mb-12 md:w-1/2 md:mb-0 md:pr-0 lg:pl-8 lg:w-2/5 xl:w-1/2 xl:pl-32 xl:pt-8">
        <p className="greeting-text text-sm leading-sm lg:text-base lg:leading-base">
          グレースメディカルグループは、
          <br />
          <br />
        </p>
        <p className="greeting-text text-sm leading-sm lg:text-base lg:leading-base">
          <span className="text-blue-900">
            「お客様に寄り添った医療サービスを」
          </span>
          <br />
          <br />
        </p>
        <p className="greeting-text text-sm leading-sm lg:text-base lg:leading-base">
          の精神を忘れずに、そして皆様から必要とされる公益に資する医療機関として、医療という枠にこだわることなく進化し続けたいと考えています。
          <br />
          <br />
        </p>
        <p className="greeting-text text-sm leading-sm lg:text-base lg:leading-base">
          そして、地域にねざす歯科治療、外来診療、訪問看護、訪問診療、居宅介護支援をし続ける医療機関でありたいと考えています。
          <br />
        </p>
      </div>
      <div className="relative ml-auto w-5/6 h-auto md:w-1/2 lg:w-3/5 xl:w-1/2">
        <StaticImage
          className="greeting-image object-top w-full h-full"
          src="../assets/img/umeda.jpg"
          alt="GMG代表 梅田 幸彦 画像"
          placeholder="blurred"
          quality="100%"
        />
        <div className="text-center text-sm w-11/12 lg:w-fit-content leading-sm absolute -bottom-8 left-1/2 transform -translate-x-1/2 p-2 lg:px-8 lg:py-4 bg-white shadow-lg">
          グレースメディカルグループ　代表
          <br />
          梅田 幸彦
        </div>
      </div>
    </section>
  )
}

import React from "react"
import scrollTo from "gatsby-plugin-smoothscroll"

export const MobileNav = ({ open, setOpen }) => {
  return (
    <nav
      className={`${
        open ? "overflow-y-scroll" : "max-h-0 opacity-0 pointer-events-none"
      } fixed h-screen inset-0 bg-gray-300 px-8 z-30 transition
    duration-500 pt-16 pb-4`}
    >
      <ul className="flex flex-col text-left gap-y-6 pt-16 pb-4">
        <li
          className="text-white font-bold tracking-wider text-sm"
          onClick={() => setOpen(!open)}
        >
          <button
            className="w-full block text-left"
            onClick={() => scrollTo("#greeting")}
          >
            <span className="text-primary-red mx-2">・</span>
            グレースメディカルグループとは
          </button>
          <div className="w-full h-px bg-white mt-6" />
        </li>
        <li
          className="text-white font-bold tracking-wider text-sm"
          onClick={() => setOpen(!open)}
        >
          <button
            className="w-full block text-left"
            onClick={() => scrollTo("#concept-movie")}
          >
            <span className="text-primary-red mx-2">・</span>紹介動画
          </button>
          <div className="w-full h-px bg-white mt-6" />
        </li>
        <li
          className="text-white font-bold tracking-wider text-sm"
          onClick={() => setOpen(!open)}
        >
          <button
            className="w-full block text-left"
            onClick={() => scrollTo("#banner01")}
          >
            <span className="text-primary-red mx-2">・</span>医療を紡ぐ01
          </button>
          <div className="w-full h-px bg-white mt-6" />
        </li>
        <li
          className="text-white font-bold tracking-wider text-sm"
          onClick={() => setOpen(!open)}
        >
          <button
            className="w-full block text-left"
            onClick={() => scrollTo("#banner02")}
          >
            <span className="text-primary-red mx-2">・</span>医療を紡ぐ02
          </button>
          <div className="w-full h-px bg-white mt-6" />
        </li>
      </ul>
    </nav>
  )
}
